<template>
    <centered-layout :loading="pageLoading">
        <page-message :logo-path="clientLogo" v-if="!emailAddress">
            There was a problem displaying this page.
        </page-message>
        <w-form-message :logo-path="clientLogo" :title="title" v-else-if="codeInvalid">
            <p>This link is invalid or has expired.</p>
            <template slot="actions">
                <router-link :to="{name: loginPage}" slot="actions">Go to the login page?</router-link>
            </template>
        </w-form-message>
        <w-auth-change-password-form
            :custom-rules="passwordRules"
            :description-text="description"
            :errors="changePasswordErrors"
            :item="formItem"
            :loading="loading"
            :logo-src="clientLogo"
            :show-email="false"
            :title="title"
            @change="setPassword"
            @submit="submit"
            v-else-if="!passwordChanged"
        >
            <v-flex xs12>
                <w-password-requirements
                    :password="password"
                    :requirements="passwordOptions"
                ></w-password-requirements>
            </v-flex>
        </w-auth-change-password-form>
        <w-form-message :logo-path="clientLogo" title="Change Password" v-else>
            <p>Your password has been successfully changed.</p>
            <p>You are now being redirected. If you are not redirected automatically,
                <a :href="redirectUrlWithQuery">click here.</a>
            </p>
            <v-progress-linear indeterminate></v-progress-linear>
        </w-form-message>
    </centered-layout>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex'
    import {LOGIN_PAGE} from '@/router/route-names'
    import LoginMixin from '@/mixins/login-mixin'
    import WValidationMixin from '@wf/mixins/forms/w-validation-mixin'
    import CenteredLayout from '@/layouts/CenteredLayout.vue'
    import WAuthChangePasswordForm from '@wf/components/WAuthChangePasswordForm'
    import WPasswordRequirements from '@windsor-telecom/windsor-framework/src/components/WPasswordRequirements'
    import PageMessage from '@/components/PageMessage'
    import WFormMessage from '@wf/components/WFormMessage'
    import PasswordMixin from '@/mixins/password-mixin'

    export default {
        name: 'ChangePasswordPage',
        mixins: [LoginMixin, WValidationMixin, PasswordMixin],
        metaInfo () {
            const vm = this
            return {
                title: vm.loading ? 'Authorising Request' : vm.title
            }
        },
        data: () => ({
            description: 'Our records show that you need to change your password. Please change your password using the form below.',
            password: '',
            codeInvalid: false,
            pageLoading: false,
            loading: false,
            passwordChanged: false,
            changePasswordErrors: [],
            loginPage: LOGIN_PAGE
        }),
        props: {
            clientId: {
                type: String,
                required: true
            },
            publicKey: {
                type: String,
                required: true
            },
            userId: {
                type: String,
                required: true
            },
            accountCode: {
                type: String,
                required: true
            },
            code: {
                type: String,
                required: true
            },
            emailAddress: {
                type: String,
                required: true
            }
        },
        components: {
            WFormMessage,
            PageMessage,
            CenteredLayout,
            WAuthChangePasswordForm,
            WPasswordRequirements
        },
        methods: {
            ...mapActions('auth', ['changePassword', 'checkForcePasswordCode']),
            ...mapActions('user', ['resetUser']),
            submit (data) {
                const vm = this
                vm.loading = true
                vm.changePassword({
                    code: vm.code,
                    user_id: vm.userId,
                    account_code: vm.accountCode,
                    password: data.password,
                    confirm_password: data.current_password
                }).then((response) => {
                    vm.handleLoginResponse(response)
                    vm.loading = false
                    vm.passwordChanged = true
                }, (response) => {
                    vm.changePasswordErrors = response.errors
                    vm.loading = false
                })
            }
        },
        computed: {
            title () {
                return `Change Password - ${this.clientName}`
            },
            formItem () {
                const vm = this
                return {
                    account_code: vm.accountCode,
                    email_address: vm.emailAddress,
                    user_id: vm.userId,
                    code: vm.code
                }
            },
            passwordRules () {
                const vm = this
                return [
                    vm.validationRules.generic.required,
                    vm.validationRules.password.minLength,
                    vm.validationRules.password.minNumbers,
                    vm.validationRules.password.minSymbols,
                    vm.validationRules.password.minLowercase,
                    vm.validationRules.password.minUppercase
                ]
            },
            ...mapGetters('auth', ['clientLogo', 'clientName']),
            ...mapGetters('user', {passwordOptions: 'userPasswordRequirements'})
        },
        created () {
            const vm = this
            const params = {
                userId: vm.userId,
                code: vm.code,
                clientId: vm.clientId,
                publicKey: vm.publicKey,
                accountCode: vm.accountCode
            }
            vm.pageLoading = true
            vm.checkForcePasswordCode(params).then(() => {
                vm.pageLoading = false
                vm.changePasswordErrors = []
            }, (response) => {
                vm.codeInvalid = true
                vm.pageLoading = false
                vm.changePasswordErrors = response.errors
            })
        },
        destroyed () {
            this.resetUser()
        }
    }
</script>
